import { Button } from '@/commons';
import { getProfile } from '@/services/users';
import { getBalance, setUser, useDispatch, useSelector } from '@/store';

import { useEffect, useState } from 'react';
import RecoverFromGameModal from './recoverFromGameModal.component';
import SendToGameModal from './sendToGameModal.component';

type IProps = {
  part: any;
  wallet: string;
  isInGame?: boolean;
};

const ShareInGame = (props: IProps) => {
  const { part, wallet, isInGame } = props;
  const dispatch = useDispatch();

  const [visibleModal, setVisibleModal] = useState('');
  const user = useSelector((state: any) => state.user);

  useEffect(() => {
    const fetchUser = async () => {
      const result = await getProfile();

      const userData = {
        name: result.name,
        email: result.email,
        wallet,
        associatedWallet: result.wallet,
      };

      dispatch(setUser(userData));
    };
    wallet && fetchUser();
    // eslint-disable-next-line
  }, []);

  const handleShare = async () => setVisibleModal('sendToGame');
  const handleRetrieve = async () => setVisibleModal('recoverFromGame');

  const handleRefresh = () => {
    setTimeout(async () => {
      dispatch(getBalance());
      setVisibleModal('');
    }, 2000);
  };

  return (
    <>
      {user?.wallet?.toLowerCase() === user?.associatedWallet?.toLowerCase() && isInGame && (
        <>
          <Button
            // onClick={isInGame ? handleRetrieve : handleShare}
            // text={isInGame ? 'Recover from game' : 'Use in game'}
            onClick={handleRetrieve}
            text={'Recover from game'}
          />
        </>
      )}

      <SendToGameModal
        isOpen={visibleModal === 'sendToGame'}
        onClose={() => setVisibleModal('')}
        onSendToGame={handleRefresh}
        part={part}
        wallet={wallet}
      />

      <RecoverFromGameModal
        isOpen={visibleModal === 'recoverFromGame'}
        onClose={() => setVisibleModal('')}
        onRecoverFromGame={handleRefresh}
        part={part}
        wallet={wallet}
      />
    </>
  );
};

export default ShareInGame;

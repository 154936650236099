/** Vendors */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/** Utils */
import { setPaginationFilter, useDispatch, useSelector } from '@/store';
import { getAuctionWins } from '@/utils/getAuctionWins';
import toast from '@/utils/customToast';

/** Components */
import Filters from '@/containers/filters/filters.component';
import PiecesList from '@/containers/piecesList/piecesList.component';

/** Styles */
import css from './marketplace.module.scss';

const Marketplace = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const wallet = useSelector((store: any) => store.user.wallet)?.toLowerCase();

  /** Handlers */
  const handleResetPagination = () => {
    dispatch(setPaginationFilter(0));
  };

  /** Init */
  useEffect(() => {
    getAuctionWins(wallet).then(wins => {
      wins.map((item: any, index: number) => {
        setTimeout(
          () =>
            toast.info(`You are the winner of the auction for the medapart #${item.token}`, {
              position: 'bottom-right',
              theme: 'colored',
              onClick: () => navigate(`/details/${item.token}`),
            }),
          index * 1000
        );
        return true;
      });
    });
  }, [wallet, navigate]);

  useEffect(()=> {
    toast.warning(`Marketplace is under manteinance. We are working on new features that will introduce new ways to interact with NFTs.`,{
      autoClose: false,
      closeOnClick: false
    })
  },[])

  return (
    <main className={css['marketplace']}>
      <div className={css['filters']}>
        <Filters onFilter={handleResetPagination} />
      </div>
      <div className={css['main']}>
        <PiecesList />
      </div>
    </main>
  );
};

export default Marketplace;
